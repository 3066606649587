<template>
  <app-form-view
    app="sale"
    model="quotationheader"
    api-url="sale/quotation-header/"
    :title="$t('menu.quotationHeader')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="contactID"
            rules="required"
            type="select-server"
            :label="$t('fields.customerName')"
            :view="view"
            :binds="{
              apiUrl: 'contact/contact/?active=true&is_employee=false'
            }"
            v-model="formData.contact_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveContactID"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact_id"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="quotation_number"
            rules="required"
            :label="$t('fields.quotation_number')"
            :view="view"
            v-model="formData.quotation_number"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveQuotationNumber"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_quotation_number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="issueDate"
            type="datepicker"
            :label="$t('fields.issueDate')"
            :view="view"
            v-model="formData.issue_date"
          />

          <app-input
            v-if="isSentToApprove"
            name="approveissueDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_issueDate"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="expiredDate"
            type="datepicker"
            :label="$t('fields.expirationDate')"
            :view="view"
            v-model="formData.expired_date"
          />

          <app-input
            v-if="isSentToApprove"
            name="approveExpiredDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_expiredDate"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="ref"
            rules="required"
            :label="$t('fields.ref')"
            :view="view"
            v-model="formData.ref"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveRef"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_ref"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="status"
            type="select"
            rules="required"
            :label="$t('fields.status')"
            :items="[
              { label: $t('label.draft'), value: 'draft' },
              { label: $t('label.sent'), value: 'sent' },
              { label: $t('label.confirm'), value: 'confirm' },
              {
                label: $t('label.partially_invoiced'),
                value: 'partially_invoiced'
              },
              { label: $t('label.invoice'), value: 'invoice' }
            ]"
            :view="view"
            v-model="formData.status"
          >
            <template v-slot:view-value="{ value }">
              {{ $t(`label.${value}`) }}
            </template>
          </app-input>

          <app-input
            v-if="isSentToApprove"
            name="approveStatus"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_status"
          />
        </v-col>
      </v-row>
      <v-card v-if="mode !== 'create'">
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>Order Line</v-tab>
          <v-tab>ตั๋วต้นทุน</v-tab>
          <v-tab>Audit Plan</v-tab>
          <v-tab>Audit Team</v-tab>
          <v-tab>Property And Agreement</v-tab>
          <v-tab>Document</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Order Line -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-edit
                    app="sale"
                    model="quotationorder"
                    :headers="quotationOrderHeaders"
                    :search="search"
                    :serverItems="items"
                    :clientItems="items"
                    :loading="loading"
                    :server-items-length="serverItemsLength"
                    :hide-delete="hideDelete || mode !== 'edit'"
                    v-bind="binds"
                    v-model="selected"
                    @server="getItems"
                    @client="getClientItems"
                    @delete="onDeleteItem"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view--header">
                          <v-toolbar flat>
                            <v-toolbar-title>Order Line</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog v-model="dialog" max-width="500px">
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="selected.length && mode === 'edit'"
                                    color="error"
                                    @click="onDelete(selected)"
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="editedItem.product_id"
                                          name="product"
                                          type="select-server"
                                          :label="$t('fields.product_name')"
                                          :view="view"
                                          :binds="{
                                            apiUrl:
                                              'product/product/?active=true'
                                          }"
                                          @input="getDataFromProduct"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="description"
                                          type="text"
                                          :label="
                                            $t('fields.product_description')
                                          "
                                          :view="view"
                                          v-model="editedItem.description"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="start_date"
                                          type="datepicker"
                                          :label="$t('fields.startDate')"
                                          :view="view"
                                          v-model="editedItem.start_date"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="end_date"
                                          type="datepicker"
                                          :label="$t('fields.endDate')"
                                          :view="view"
                                          v-model="editedItem.end_date"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="audit_plan"
                                          type="text"
                                          :label="$t('fields.auditPlan')"
                                          :view="view"
                                          v-model="editedItem.audit_plan"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="qty"
                                          type="text"
                                          :label="$t('fields.qty')"
                                          :view="view"
                                          v-model="editedItem.qty"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="price"
                                          type="text"
                                          :label="$t('fields.product_price')"
                                          :view="view"
                                          v-model="editedItem.price"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="discount"
                                          type="text"
                                          :label="$t('fields.discount')"
                                          :view="view"
                                          v-model="editedItem.discount"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="vat"
                                          type="text"
                                          :label="$t('fields.vat')"
                                          :view="view"
                                          v-model="editedItem.vatRate"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="wht"
                                          type="text"
                                          :label="$t('fields.wht')"
                                          :view="view"
                                          v-model="editedItem.wht"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveQuotationOrder"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-subtitle-1">Total Untaxed Amount</td>
                        <td class="text-subtitle-1 text-right">
                          {{ $accountingFormat(sumTotalUntaxedAmount) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-subtitle-1">Vat 7%</td>
                        <td class="text-subtitle-1 text-right">
                          {{ $accountingFormat(sumVat7) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-subtitle-1">WHT</td>
                        <td class="text-subtitle-1 text-right">
                          {{ $accountingFormat(sumWHT) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- ตั๋วต้นทุน -->
          <v-tab-item> </v-tab-item>
          <!-- Audit Plan -->
          <v-tab-item> </v-tab-item>
          <!-- Audit Team -->
          <v-tab-item> </v-tab-item>
          <!-- Document -->
          <v-tab-item> </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '@components/AppTable.vue'
export default {
  name: 'quotation-header-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      editedItem: {
        product_id: null,
        description: null,
        start_date: null,
        end_date: null,
        audit_plan: null,
        qty: null,
        price: null,
        discount: null,
        vat: null,
        vatRate: null,
        wht: null,
        total: null
      },
      tab: null,
      loading: false,
      search: '',
      items: [],
      serverItemsLength: 0,
      selected: [],
      dialog: false,
      approveItems: [
        'approveContactID',
        'approveQuotationNumber',
        'approveissueDate',
        'approveExpiredDate',
        'approveRef',
        'approveStatus'
      ],

      total: null
    }
  },
  props: {
    hideEdit: {
      type: Boolean,
      default: true
    },
    hideDelete: {
      type: Boolean,
      default: false
    },
    binds: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    quotationOrderHeaders() {
      return [
        {
          text: this.$t('fields.product_name'),
          value: 'product_id.name'
        },
        {
          text: this.$t('fields.description'),
          value: 'product_id.description'
        },
        {
          text: this.$t('fields.startDate'),
          value: 'start_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.endDate'),
          value: 'end_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.auditPlan'),
          value: 'audit_plan'
        },
        {
          text: this.$t('fields.qty'),
          value: 'qty'
        },
        {
          text: this.$t('fields.product_price'),
          value: 'price'
        },
        {
          text: this.$t('fields.discount'),
          value: 'discount'
        },
        {
          text: this.$t('fields.vat'),
          value: 'vat'
        },
        {
          text: this.$t('fields.wht'),
          value: 'wht'
        },
        {
          text: this.$t('fields.total'),
          value: 'total'
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    sumTotalUntaxedAmount() {
      return this.$sumField(this.items, 'total')
    },
    sumVat7() {
      return this.$sumField(this.items, 'vat')
    },
    sumWHT() {
      return this.$sumField(this.items, 'wht')
    }
  },
  created() {
    if (this.$route.name === 'quotationHeaderCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'quotationHeaderCreate'
      })
    }
  },
  watch: {
    search(newValue, oldValue) {
      this.serverSide && newValue !== oldValue && this.onServer()
    },
    'formData.contact_id.name': function (value) {
      if (this.$route.name === 'quotationHeaderEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'quotationHeaderEdit',
          text: value,
          to: {
            name: 'quotationHeaderEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    prepareData(data) {
      data.contact_id = data.contact_id.id
      return data
    },
    onInput(value) {
      /**
       * Set input for v-model
       * @type {Event}
       */
      this.$emit('input', value)
    },
    async getItems(options = null) {
      this.loading = true
      var params = {
        ...this.query
      }
      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        await this.$api({
          method: 'get',
          url: `sale/quotation-order/?quotation_header_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.items = data.results
        })
      }
      this.loading = false
    },
    getClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(
        items,
        () => {
          return this.$api({
            method: 'delete',
            url: `sale/quotation-order/`,
            data: {
              pks: items.map((value) => value.id)
            }
          }).then(() => {
            this.selected = []
            this.getItems()
          })
        },
        () => {
          this.loading = false
        }
      )
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    getDataFromProduct() {
      this.loading = true
      if (
        this.editedItem.product_id != null &&
        this.editedItem.product_id != undefined
      ) {
        this.$api({
          method: 'get',
          url: 'product/product/?id=' + this.editedItem.product_id.id,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.$nextTick(() => {
            this.editedItem = {
              ...data.results[0],
              product_id: this.editedItem.product_id,
              price: data.results[0].product_price,
              vat: data.results[0].vat,
              vatRate: data.results[0].vatRate,
              wht: data.results[0].whtRate
            }
          })
        })
      }
    },
    saveQuotationOrder() {
      this.loading = true
      let vatRate = this.editedItem.vat ? this.editedItem.vatRate : 0
      const untaxedAmount =
        this.editedItem.qty * this.editedItem.price - this.editedItem.discount
      const totalVat =
        ((this.editedItem.qty * this.editedItem.price -
          this.editedItem.discount) *
          vatRate) /
        100
      const totalWht =
        ((this.editedItem.qty * this.editedItem.price -
          this.editedItem.discount) *
          this.editedItem.wht) /
        100

      this.editedItem.total = untaxedAmount + totalVat - totalWht
      this.editedItem.vat = totalVat
      this.editedItem.wht = totalWht
      this.editedItem.quotation_header_id = this.$route.params.id
      this.editedItem.product_id = this.editedItem.product_id.id
      this.$api({
        method: 'post',
        url: `sale/quotation-order/`,
        data: this.editedItem
      }).then(() => {
        this.loading = false
        this.close()
        this.getItems()
      })
    }
  }
}
</script>
